import Glide from "@glidejs/glide";
import Sizes from "./glide_fixed_width_slides";

const defaultOptions = {
  type: 'carousel',
  gap: 30,
  slideWidth: 400,
  perView: 3,
  peek: {
    before: 20,
    after: 40
  },
  breakpoints: {
    425: {
      slideWidth: 285
    }
  }
}

export function initializeArticleSlider(articleSliderSelector, options=defaultOptions){
  const articleSliderEl = document.querySelector(articleSliderSelector);
  if (articleSliderEl) {
    const articleSlider = new Glide(articleSliderSelector, options);
    articleSlider.mount({
      Sizes
    });
  }
}
